<template>
    <div class="resume">
        <div class="title">
            <div class="sub-title">{{title}} {{year}}年{{month}}月 - 数据上报 <el-button @click="$router.go(-1)">返回上一页</el-button></div>
            
            <!-- <div class="sub-filtter"><el-button @click="openadd" icon="el-icon-plus" size="small" :disabled="nowyear!=year || nowmonth!=month">添加记录</el-button></div> -->
        </div>
        <el-card class="box-card" style="min-height:100%;padding-bottom:20px; ">
            <div class="list" v-loading="loading">
                <el-table
                :data="list"
                border
                :header-cell-style="{'font-weight':'bold','color':'#555555','padding':'6px 0'}"
                stripe
                style="width: 100%">
                    <el-table-column
                    prop="positionTitle"
                    label="岗位名称">
                    </el-table-column>
                    <el-table-column
                    prop="demandNumber"
                    align="center"
                    label="需求人数">
                    </el-table-column>
                    <el-table-column
                    prop="jobNumber"
                    align="center"
                    label="求职人数">
                    </el-table-column>
                    <el-table-column
                    prop="employmentRatio"
                    align="center"
                    label="供需比">
                    </el-table-column>

                </el-table>
            </div>
        </el-card>

    </div>
</template>
<script>
import companyRequest from '../../api/company'
export default {
    data(){
        return{
            status:1,
            nowyear:"",
            nowmonth:"",
            loading:false,
            year:"",
            month:"",
            user:null,
            title:"",
            mid:"",
            list:[],
            showpop:false,
            form:{id:null,month:null,companyId:null,positionTitle:null,demandNumber:null,jobNumber:null}
        }
    },
    created(){
        var date = new Date();
        this.nowyear=date.getFullYear();
        let zsm=date.getMonth()+1;
        this.nowmonth=zsm
        this.year=this.$route.query.y
        this.month=this.$route.query.m
        this.title=this.$route.query.t
        this.mid=this.$route.query.mid
        this.form.month=this.month
        this.form.companyId=this.mid
        this.getList()
    },
    methods:{
        deleteitem(row){
            companyRequest.delData({id:row.id}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("删除成功！")
                    this.getList()
                }else{
                    this.$message.error("删除失败！")
                }
            })
        },
        openedit(row){
            for(let k in this.form){
                this.form[k]=row[k]
            }
            this.status=2
            this.showpop=true
        },
        openadd(){
            this.form={id:null,month:this.month,companyId:this.mid,positionTitle:null,demandNumber:null,jobNumber:null}
            this.status=1
            this.showpop=true
        },
        getList(){
            this.loading=true
            companyRequest.queryData({year:this.year,companyId:this.mid,month:this.month}).then(res=>{
                console.log(res)
                this.loading=false
                this.list=res.data
            })
        },
        getclose(){
            this.form={id:null,month:this.month,companyId:this.mid,positionTitle:null,demandNumber:null,jobNumber:null}
            this.showpop=false
        },
        getconfirm(){
            if(this.status==1){
                companyRequest.insertData(this.form).then(res=>{
                    if(res.success){
                        this.$message.success("添加成功！")
                        this.getList()
                        this.getclose()
                    }
                })
            }else{
                companyRequest.editData(this.form).then(res=>{
                    if(res.success){
                        this.$message.success("编辑成功！")
                        this.getList()
                        this.getclose()
                    }
                })
            }
        }

    }
}
</script>
<style scoped>

</style>